
.new-sino-box{
  background: #fff;
  padding:10px;
}
.new-sino-header{
  height: 39px!important;
  background: rgb(243, 243, 243);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.el-input__inner{
  margin-right: 5px;
}
.new-sino-input{
  width: 200px;
  margin-right: 10px;
}
.new-form-input{
  width: 300px;
}
.new-form-input .el-input{
  width: 300px;
}

